import React from 'react'
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Sub_Navigation from "../components/common/navigation/sub_navigation"
import data from '../data/data-json.json'
import compatibility from '../data/compatibility.json'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import styled from "styled-components"
import { Section, Container } from "../components/global"
import GetStarted from "../components/sections/getstarted"



export default class QuotePage extends React.Component {
  constructor(props){
    super(props)
    console.log('Props: ', props);
    global.options = [];
    global.messages = [];
    this.state = { 
    options: [],
    nodes: [],
    messages: [],
    isHidden: true,
    Data1: props
       }
}
 
handleChange = (currentNode, selectedNodes) => {
  console.log('SelectedNodes: ', selectedNodes);
  console.log('Props: ', this.props);
  console.log('state data: ', this.state);
  global.nodes = selectedNodes;
  console.log('Global Nodes: ', global.nodes);
  console.log('Current Node: ', currentNode);
  if (currentNode._depth === 0) {console.log('Children: ', currentNode._children);}

}  
  

  updateTable = () => {
    global.messages = [];
    global.options = [];
    this.setState({ messages: [], isHidden: true }, () => {
      console.log("Updated State", this.state.messages);
    }); 
    for ( let i = 0; i < global.nodes.length; i++) {
    if ( global.nodes[i]._id.length > 10) {global.options.push(global.nodes[i].label);
    }; 
    if ( global.nodes[i]._id.length < 10) {
      global.messages.push(global.nodes[i].label);
      this.setState({
        isHidden: false
      });
    }
  }
    console.log("Selection: ", global.options);
    console.log("Messages ", global.messages); 
    this.setState({ messages: global.messages }, () => {
      console.log("Updated State", this.state.messages);
    }); 
  }

  
 componentDidMount() {
       }
 
    

  render() {
        return (
  <Layout>  
    <SEO title="Page two" />
    <Sub_Navigation />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    
    <StyledContainer>   
    <SectionTitle>Compatibility Check</SectionTitle>
    <Subtitle2>Garage Door Opener Model</Subtitle2>
    <p>Models are grouped by brand initial in alphabetical order. Please type model number or choose from the dropdown menu to make your selection.</p>
    <br></br>
    <ButtonContainer>   
    <DropdownTreeSelect data={data} mode="hierarchical" keepTreeOnSearch="true" onChange={this.handleChange}/>
    <ActionButton onClick={this.updateTable}>Update Table</ActionButton>
   </ButtonContainer>
    <br></br>
    <Subtitle>Compatibility of the garage door opener model you selected is displayed below: </Subtitle>
    <br></br>
    <br></br>
    <table>
    <tbody>
    <tr id="titles">
      <th style={{width:'200px', textAlign: 'left'}}>Model</th>
      <th style={{width:'400px', textAlign: 'left'}}>Brand</th>
      <th style={{width:'400px', textAlign: 'left'}}>Compatible?</th>
    </tr>
    {global.options.map(function(d, idx){return (
    <tr id= {d} key={d}>
       <td style={{columnWidth: '150px', textAlign: 'left'}}key={idx} >{d}</td>
       <td style={{columnWidth: '300px', textAlign: 'left'}}>{compatibility[d].brand}</td>
       <td style={{width: '500px', textAlign: 'left'}}>{compatibility[d].comp}</td>
    </tr>)})}
    </tbody>
    </table>
    <br></br>
    <br></br>
    <br></br>
    <div>{!this.state.isHidden && <Message />} {this.state.messages.map(function(d, idx){return (<span>{d}, </span>)})}</div>

    <CommentText><strong>Note: </strong> If your garage door opener model is not compatible, please contact us at <a href="mailto:support@arcoinnovation.com">support@arcoinnovation.com</a>. Our technical staff might be able to offer an accessory to make it work.</CommentText>
    </StyledContainer>  
     
     <GetStarted />    
            
  </Layout>
)
   
  }
 
}


const Message = () => (
  <div>
       The following selections are not Garage Door Opener models,rather brands or groups and were exluded from the table:
    </div>
  )


const StyledContainer = styled(Container)`
margin: 40px auto;
font-size: 16;
`

const ButtonContainer = styled(Container)`
display: flex;
margin: auto;
font-size: 10;
@media (max-width: ${props => props.theme.screen.md}) {
}
@media (max-width: ${props => props.theme.screen.sm}) {
  display: block;
  margin-left: 0;
}
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: left;
  text-align: left;
`

const Subtitle2 = styled.h5`
  font-size: 18;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: left;
  `

const Subtitle = styled.h5`
  font-size: 18;
  color: ${props => props.theme.color.secondary};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: left;
`
const CommentText = styled.p`
  text-align: left;

  `

  const ActionButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

